<template>
  <div>
    <market-form
      ref="marketForm"
      :is-loading="isLoading"
      :is-market-loading="isLoading"
      :preview="preview"
      :market="market"
      :form-title="formTitle"
      submit-button-text="Update"
      @submit="submit"
    >
      <template v-slot:card-subtitle>
        <div class="mt-6"></div>
        <v-btn color="primary" :disabled="!preview" @click="preview = false">
          <v-icon small class="mr-3">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          color="primary"
          class="ml-3"
          outlined
          :disabled="preview"
          @click="cancelEditing"
        >
          Cancel editing
        </v-btn>
      </template>
    </market-form>
  </div>
</template>

<script>
import MarketForm from "@/views/Panel/views/Markets/components/MarketForm";
import { mapActions } from "vuex";
export default {
  name: "Market",
  components: { MarketForm },
  data() {
    return {
      isLoading: false,
      preview: true,
      market: null
    };
  },
  computed: {
    formTitle() {
      const baseText = this.preview ? "Preview market" : "Edit market";
      return `${baseText}${this.market ? ": " + this.market.name : ""}`;
    }
  },
  mounted() {
    this.handleGetMarket();
  },
  watch: {
    "$route.params.id"() {
      this.preview = true;
      this.handleGetMarket();
    }
  },
  methods: {
    ...mapActions({
      getMarket: "api/management/market/getMarket",
      updateMarket: "api/management/market/updateMarket"
    }),
    cancelEditing() {
      this.$refs.marketForm.setMarketForm();
      this.preview = true;
    },
    handleGetMarket() {
      this.isLoading = true;

      this.getMarket(this.$route.params.id)
        .then(response => {
          this.market = response.data;
        })
        .catch(error => {
          this.$notifier.notify({
            message: "Cannot get market",
            color: "error"
          });
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submit(data) {
      this.isLoading = true;
      const { id } = this.$route.params;

      this.updateMarket({ id, data })
        .then(() => {
          this.$root.$emit("refresh-market-list");
          this.$refs.marketForm.handleCancel();
          this.$notifier.notify({
            message: "Market updated successfully",
            color: "success"
          });
        })
        .catch(error => {
          this.$notifier.notify({
            message: "Cannot update market",
            color: "error"
          });
          console.log({ error });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
